import { createRoutine, promisifyRoutine } from "redux-saga-routines";

export const addTrackedPodcast = createRoutine("ADD_TRACKED_PODCAST");
export const addTrackedPodcastPromise = promisifyRoutine(addTrackedPodcast);

export const setTrackedEpisodeChecked = createRoutine(
  "SET_TRACKED_EPISODE_CHECKED"
);
export const setTrackedEpisodeCheckedPromise = promisifyRoutine(
  setTrackedEpisodeChecked
);

export const checkExternalIds = createRoutine("CHECK_EXTERNAL_IDS");
export const checkExternalIdsPromise = promisifyRoutine(checkExternalIds);

export const getProDataForPodcast = createRoutine("GET_PRO_DATA_FOR_PODCAST");
export const getProDataForPodcastPromise =
  promisifyRoutine(getProDataForPodcast);

export const saveProNote = createRoutine("SAVE_PRO_NOTE");
export const saveProNotePromise = promisifyRoutine(saveProNote);

export const getProDataForEpisode = createRoutine("GET_PRO_DATA_FOR_EPISODE");
export const getProDataForEpisodePromise =
  promisifyRoutine(getProDataForEpisode);

export const getTranscriptDataForEpisode = createRoutine(
  "GET_TRANSCRIPT_DATA_FOR_EPISODE"
);
export const getTranscriptDataForEpisodePromise = promisifyRoutine(
  getTranscriptDataForEpisode
);

export const getPodcastAd = createRoutine("GET_PODCAST_AD");
export const getPodcastAdPromise = promisifyRoutine(getPodcastAd);

export const loadEpisodesOfEpisodeGroup = createRoutine(
  "LOAD_EPISODES_OF_EPISODE_GROUP"
);

export const flagAircheck = createRoutine("FLAG_EPISODE_AIRCHECK");
export const flagAircheckPromise = promisifyRoutine(flagAircheck);

export const unflagAircheck = createRoutine("UNFLAG_EPISODE_AIRCHECK");
export const unflagAircheckPromise = promisifyRoutine(unflagAircheck);

export const voteContact = createRoutine("VOTE_CONTACTS");
export const voteContactPromise = promisifyRoutine(voteContact);

export const cancelContactVote = createRoutine("CANCEL_CONTACTS_VOTE");
export const cancelContactVotePromise = promisifyRoutine(cancelContactVote);

export const createAdvertiseInterest = createRoutine(
  "CREATE_ADVERTISEMENT_INTEREST"
);
export const createAdvertiseInterestPromise = promisifyRoutine(
  createAdvertiseInterest
);

export const flagDemographics = createRoutine("FLAG_PODCAST_DEMOGRAPHICS");
export const flagDemographicsPromise = promisifyRoutine(flagDemographics);

export const unflagDemographics = createRoutine("UNFLAG_PODCAST_DEMOGRAPHICS");
export const unflagDemographicsPromise = promisifyRoutine(unflagDemographics);
export const flagReach = createRoutine("FLAG_PODCAST_REACH");
export const flagReachPromise = promisifyRoutine(flagReach);

export const unflagReach = createRoutine("UNFLAG_PODCAST_REACH");
export const unflagReachPromise = promisifyRoutine(unflagReach);

export const flagSocial = createRoutine("FLAG_PODCAST_SOCIAL");
export const flagSocialPromise = promisifyRoutine(flagSocial);

export const unflagSocial = createRoutine("UNFLAG_PODCAST_SOCIAL");
export const unflagSocialPromise = promisifyRoutine(unflagSocial);

export const getPodcastRanksData = createRoutine("LOAD_PODCAST_RANKS_DATA");
export const getPodcastRanksDataPromise = promisifyRoutine(getPodcastRanksData);

export const getPodcastChartsData = createRoutine("LOAD_PODCAST_CHARTS_DATA");
export const getPodcastChartsDataPDFPromise =
  promisifyRoutine(getPodcastChartsData);
