import ScreenSizes from "./ScreenSizes";

export default {
  columnContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    [ScreenSizes.lgAndAbove]: {
      flexDirection: "row",
    },
  },
  mainColumn: {
    flex: 2,
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    [ScreenSizes.lgAndAbove]: {
      width: "calc(100% - 250px)",
      paddingRight: "4%",
      paddingLeft: "2rem",
      marginLeft: "-2rem",
    },
    [ScreenSizes.xlAndAbove]: {
      width: "calc(100% - 300px)",
      paddingRight: "5%",
    },
  },
  sidebarContainer: {
    display: "flex",
    flex: 1,
    // maxWidth: 300,
    flexDirection: "column",

    [ScreenSizes.lgAndAbove]: {
      maxWidth: 250,
      flex: "0 0 250px",
    },
    [ScreenSizes.xlAndAbove]: {
      maxWidth: 300,
      flex: "0 0 300px",
    },
  },
};
