import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const UserProfileSharingLinks = Loadable(() =>
  import("./UserProfileSharingLinks")
);

function UserProfileSharingLinksAsyncLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const UserProfileSharingLinksContainer = (props) => {
  return (
    <UserProfileSharingLinks
      {...props}
      fallback={<UserProfileSharingLinksAsyncLoading />}
    />
  );
};

export default UserProfileSharingLinksContainer;
